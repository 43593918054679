@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');

@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import './animations/spin.css';
@import './animations/spinner.css';
@import './fonts/thor.css';
@import './components/snackbar.css';
@import './components/button.css';
@import './components/link.css';
@import './components/flyout.css';
@import './components/typography.css';
@import './components/sidenav.css';
@import './components/background.css';
@import './components/qrreader.css';
@import './components/FavoriteButton.css';

@import 'tailwindcss/utilities';

:root {
  --color-primary: #319795;
  --color-primary-light: #38b2ac;
  --color-primary-lighter: #4fd1c5;
  --color-primary-lightest: #e6fffa;
  --color-primary-dark: #2c7a7b;
  --color-primary-darker: #285e61;
  --color-primary-darkest: #234e52;
  --color-secondary: #805ad5;
  --color-secondary-light: #704cc5;
  --color-secondary-lighter: #805ad5;
  --color-secondary-lightest: #9068e5;
  --color-secondary-dark: #6a4bae;
  --color-secondary-darker: #553d89;
  --color-secondary-darkest: #2d2243;

  --font-primary: 'Space Mono', ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

::selection {
  background-color: var(--color-primary);
  @apply text-white;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
  border-radius: 20px;
}

* {
  outline: 0;
  scrollbar-color: var(--color-primary) transparent;
  scrollbar-width: thin;
}

button,
object,
embed {
  outline: 0;
  border: 0;
}

input,
a:focus {
  outline: 0;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}
