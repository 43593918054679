
.spin-container{
  display:flex;
  justify-content:center;
  align-items:center;
}

.spin-container div{
  position:absolute;
  width:90px;height:51px;
}

.spin-container div:nth-of-type(2){transform:rotate(60deg)}
.spin-container div:nth-of-type(3){transform:rotate(-60deg)}


.spin-container div div{width:100%;height:100%;position:relative}

.spin-container div div span{
  position:absolute;
  width:12px;height:0%;
  background:#8c8c8c;z-index:999999;
}

.spin-h1{left:0;
  animation: load1 3.6s ease infinite;
}
.spin-h2{right:0;
  animation: load2 3.6s ease .3s infinite;
}
.spin-h3{right:0;
  animation: load3 3.6s ease .6s infinite;
}
.spin-h4{right:0;
  animation: load4 3.6s ease .9s infinite;
}
.spin-h5{left:0;
  animation: load5 3.6s ease 1.2s infinite;
}
.spin-h6{left:0;
  animation: load6 3.6s ease 1.5s infinite;
}

@keyframes load1 {
  0%{bottom:0;height:0}
  6.944444444%{bottom:0;height:100%}
  50%{top:0;height:100%}
  59.944444433%{top:0;height:0}
/*   91.6666667%{top:0;height:0%;} */
}

@keyframes load2 {
  0%{top:0;height:0}
  6.944444444%{top:0;height:100%}
  50%{bottom:0;height:100%}
  59.944444433%{bottom:0;height:0}
/*   91.6666667%{bottom:0;height:0%} */
}

@keyframes load3 {
  0%{top:0;height:0}
  6.944444444%{top:0;height:100%}
  50%{bottom:0;height:100%}
  59.94444443%{bottom:0;height:0}
/*   91.6666667%{bottom:0;height:0%;} */
}

@keyframes load4 {
  0%{top:0;height:0}
  6.944444444%{top:0;height:100%}
  50%{bottom:0;height:100%}
  59.94444443%{bottom:0;height:0}
/*   91.6666667%{bottom:0;height:0%;} */
}

@keyframes load5 {
  0%{bottom:0;height:0}
  6.944444444%{bottom:0;height:100%}
  50%{top:0;height:100%}
  59.94444443%{top:0;height:0}
/*   91.6666667%{top:0;height:0%;} */
}

@keyframes load6 {
  0%{bottom:0;height:0}
  6.944444444%{bottom:0;height:100%}
  50%{top:0;height:100%}
  59.94444443%{top:0;height:0}
/*   91.6666667%{top:0;height:0%;} */
}