.snackbar {
	@apply fixed;
	@apply bottom-0;
	@apply right-0;
	@apply left-0;
	@apply m-4;
	@apply py-2;
	@apply px-4;
	@apply rounded;
	@apply shadow-xl;
	@apply text-white;
	@apply bg-teal-600;
	@apply z-50;
}

.snackbar.hide {
	@apply hidden;
}

/* md */
@media (min-width: 768px) {
	.snackbar {
		@apply left-auto;
	}
}
