.qrreader {
	@apply max-w-full;
	@apply w-full;
	@apply overflow-hidden;
	@apply rounded;
	@apply shadow-lg;
}

@media (min-width: 530px) {
	.qrreader {
		@apply max-w-xs;
	}
}

@media (max-width: 530px) {
	.qrreader {
		@apply flex-1;
	}
	.qrreader > section {
		@apply h-full;
	}
}
