.btn {
	@apply shadow-md;
	@apply flex;
	@apply flex-row;
	@apply items-center;
	@apply justify-center;
	@apply bg-white;
	@apply rounded;
	@apply relative;
	@apply p-2;
}

.btn.load {
	@apply text-transparent;
}

.btn.load::after {
	content: '';
	@apply absolute;
	@apply w-4;
	@apply h-4;
	@apply border-t;
	@apply border-r;
	@apply rounded-full;
	@apply spin;
	left: 50%;
	top: 50%;
	@apply -ml-2;
	@apply -mt-2;
}

.btn.load::after {
	content: '';
}

.btn:hover {
	@apply bg-gray-100;
}

.btn.selected,
.btn:active {
	@apply bg-gray-300;
}
.btn:focus {
	@apply outline-none;
}

.fab {
	@apply btn;
	@apply rounded-full;
}

.fab:hover {
	@apply bg-gray-100;
}
.fab:active {
	@apply bg-gray-400;
}
.fab:focus {
	@apply outline-none;
}

.fab.load {
	@apply text-transparent;
}

.fab.load::after {
	content: '';
	@apply absolute;
	@apply w-4;
	@apply h-4;
	@apply border-t;
	@apply border-r;
	@apply rounded-full;
	@apply spin;
}

.fab.load::after {
	content: '';
}
