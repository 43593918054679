#favoriteOff:hover {
  cursor: pointer;
  color: rgb(0, 0, 0);
}

#favoriteOff {
  color: rgb(71, 80, 96);
  justify-self: right;
}

#favoriteOn {
  color: var(--color-primary);
  justify-self: right;
}

#favoriteOn:hover {
  cursor: pointer;
  color: var(--color-primary-dark);
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
}

.center {
  height: 3rem;
  justify-self: center;
}
