.title {
	@apply text-gray-900;
	@apply text-xl;
}
.subtitle {
	@apply text-gray-600;
	@apply text-lg;
}
.body {
	@apply text-gray-700;
	@apply text-sm;
}
.caption {
	@apply text-gray-600;
	@apply text-sm;
}
