@font-face {
	font-family: 'thor';
	src: url('../fonts/thor.eot?41065159');
	src: url('../fonts/thor.eot?41065159#iefix') format('embedded-opentype'),
		url('../fonts/thor.woff2?41065159') format('woff2'),
		url('../fonts/thor.woff?41065159') format('woff'),
		url('../fonts/thor.ttf?41065159') format('truetype'),
		url('../fonts/thor.svg?41065159#thor') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
	  font-family: 'thor';
	  src: url('../fonts/thor.svg?41065159#thor') format('svg');
	}
  }
  */

.icon-thor:before {
	font-family: 'thor';
	font-style: normal;
	font-weight: normal;
	speak: none;

	display: inline-block;
	text-decoration: inherit;
	text-align: center;

	/* For safety - reset parent styles, that can break glyph codes*/
	font-variant: normal;
	text-transform: none;

	/* Font smoothing. That was taken from TWBS */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-thor.size-xs::before {
	@apply text-xs;
}
.icon-thor.size-sm::before {
	@apply text-sm;
}
.icon-thor.size-base::before {
	@apply text-base;
}
.icon-thor.size-lg::before {
	@apply text-lg;
}
.icon-thor.size-xl::before {
	@apply text-xl;
}
.icon-thor.size-2xl::before {
	@apply text-2xl;
}
.icon-thor.size-3xl::before {
	@apply text-4xl;
}
.icon-thor.size-4xl::before {
	@apply text-4xl;
}
.icon-thor.size-5xl::before {
	@apply text-5xl;
}
.icon-thor.size-6xl::before {
	@apply text-6xl;
}

.connector-ccs:before {
	content: '\e800';
}
.connector-chademo:before {
	content: '\e801';
}
.connector-commando:before {
	content: '\e802';
}
.connector-schuko:before {
	content: '\e803';
}
.connector-type_1:before {
	content: '\e804';
}
.connector-type_2:before {
	content: '\e805';
}
.connector-type_3a:before {
	content: '\e806';
}
.connector-type_3c:before {
	content: '\e807';
}
.payment-stripe:before {
	content: '\e808';
}
.payment-paypal:before {
	content: '\e809';
}
.qr-code:before {
	content: '\e80A';
}
