.flyout {
	@apply absolute;
	@apply min-h-0;
	@apply p-2;
	@apply bg-white;
	@apply rounded;
	@apply shadow;
}

.flyout.right {
	@apply top-0;
	@apply right-0;
	@apply mr-16;
}

.flyout.left {
	@apply top-0;
	@apply left-0;
	@apply mr-16;
}

.flyout.bottom {
	@apply mt-3;
}
