.sidenav {
	@apply text-white;
	@apply font-primary;
	@apply text-right;
	@apply pb-4;
	@apply min-h-screen;
	@apply max-h-screen;
	@apply overflow-hidden;
	@apply w-full;
	@apply absolute;
	@apply z-0;
	@apply flex;
	@apply flex-col;
	background: var(--color-primary);
	/* background: linear-gradient(
		90deg,
		rgba(128, 90, 213, 1) 0%,
		rgba(121, 95, 207, 1) 8%,
		rgba(115, 100, 202, 1) 15%,
		rgba(107, 106, 196, 1) 22%,
		rgba(103, 109, 193, 1) 30%,
		rgba(96, 115, 187, 1) 38%,
		rgba(88, 121, 181, 1) 45%,
		rgba(82, 126, 176, 1) 50%,
		rgba(77, 130, 172, 1) 58%,
		rgba(72, 133, 168, 1) 65%,
		rgba(67, 137, 164, 1) 72%,
		rgba(61, 141, 159, 1) 80%,
		rgba(55, 146, 154, 1) 87%,
		rgba(49, 151, 149, 1) 95%
	); */
	background: linear-gradient(
		90deg,
		var(--color-secondary) 0%,
		var(--color-primary) 95%
	);
}

.sidenav-sibling {
	@apply min-h-screen;
	@apply w-full;
	@apply font-primary;
	@apply bg-white;
	@apply shadow-2xl;
	@apply top-0;
	@apply left-0;
	transition: all 1s ease 0s;
}

.sidenav-sibling.translated {
	@apply rounded-lg;
	@apply overflow-hidden;
	@apply h-screen;
	top: 7.5rem;
	left: -20rem;
}

/* sm */
@media (min-width: 640px) {
	.sidenav-sibling.translated {
		top: 7.5rem;
		left: -25em;
	}
}
/* md */
@media (min-width: 768px) {
	.sidenav-sibling.translated {
		top: 7.5rem;
		left: -25rem;
	}
}
/* lg */
@media (min-width: 1024px) {
	.sidenav-sibling.translated {
		top: 7.5rem;
		left: -25rem;
	}
}
/* xl */
@media (min-width: 1280px) {
	.sidenav-sibling.translated {
		top: 10rem;
		left: -30rem;
	}
}
